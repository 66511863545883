module.exports = {
  gatsbyConfig: {
    property: 'woodwardsydney',
    siteName: 'Woodward Sydney',
    siteUrl: 'https://www.woodwardsydney.com.au',
    reCaptchaDBPropertyName: 'Woodward PA',
    color: '#FFD203',
    facebook: '',
    instagram: '',
    twitter: '',
  },
  // TODO: Good way of managing API keys per site, maybe use for formbucket and mailchimp?
  contentfulConfig: {
    spaceId: '38vc2unmqsq5',
    accessToken: 'D2b0ZG2uKbPvWdW38GS0y3_hQN9AxH-iJnKijjoI0cA',
  },
};
